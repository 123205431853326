<template>
  <div class="save-form-page">
    <h6 class="ym-title">
      <el-link class="ym-title-link" href="#/listFowlOuts">出栏信息 <i class="el-icon-arrow-right"></i> </el-link>
      <span>{{ title }}</span>
    </h6>
    <div class="ym-panel-general">
      <el-form
        class="el-form1"
        :model="form"
        :rules="rules"
        ref="form"
        label-width="282px"
      >
        <el-form-item v-if="this.id" prop="afcBatchOutId" label="出栏编号：">
          <el-input
            :value="form.outNum"
            placeholder="出栏编号自动生成"
            disabled
            size="medium"
          ></el-input>
        </el-form-item>

        <el-form-item prop="productId" label="产品名称：">
          <select-page
            label="productName"
            value="productId"
            apiFile="currency"
            apiName="listProducts"
            placeholder="请选择产品名称"
            filter="productName"
            :selected.sync="productId"
            :params="productParams"
            @change="onProductChange"
            clearable
          ></select-page>
        </el-form-item>

        <el-form-item prop="afcBatchId" label="产品批次编号：">
          <el-input
            v-if="id"
            v-model="form.batchNum"
            maxlength="50"
            disabled
            size="medium"
          ></el-input>
          <select-page
            v-else
            label="batchNum"
            value="afcBatchId"
            apiFile="currency"
            apiName="listAfcBatchs"
            placeholder="请选择产品批次编号"
            filter="batchNum"
            :selected.sync="afcBatchId"
            :params="batchParams"
            @change="onBatchChange"
            clearable
            :disabled="isOpen"
          ></select-page>
        </el-form-item>

        <el-form-item prop="outCompanyId" label="出栏下游商户：">
          <select-page
            label="companyName"
            value="companyId"
            apiFile="currency"
            apiName="listCompanys"
            placeholder="请选择出栏下游商户"
            filter="companyName"
            :selected.sync="companyId"
            :params="companyParams"
            @change="onCompanyChange"
            clearable
          ></select-page>
        </el-form-item>

        <el-form-item prop="afcBatchVervels" label="出栏明细：">
          <el-input
            :value="outDetails.join(',')"
            placeholder="先选择产品批次编号再筛选出栏明细"
            size="medium"
            disabled
          ></el-input>
          <el-button
            v-if="!isChange"
            class="btn-left"
            type="text"
            @click="dialogTableVisible = true"
            >筛选</el-button
          >
          <el-button
            v-else
            class="btn-left"
            type="text"
            @click="dialogTableVisible = true"
            >修改</el-button
          >
        </el-form-item>

        <el-form-item prop="outQty" label="出栏数量：">
          <el-input
            v-model="form.outQty"
            placeholder="出栏数量自动获取"
            size="medium"
            clearable
            disabled
          ></el-input>
        </el-form-item>

        <el-form-item prop="remark" label="备注：">
          <el-input
            type="textarea"
            placeholder="请输入内容"
            v-model="form.remark"
            maxlength="50"
            show-word-limit
            size="medium"
            rows="3"
          >
          </el-input>
        </el-form-item>

        <el-form-item>
          <el-button @click="goBack" size="medium" round plain>返回</el-button>
          <el-button type="primary" @click="submit" size="medium" round
            >确认提交</el-button
          >
        </el-form-item>
      </el-form>
    </div>
    <!-- 出栏明细筛选弹出窗 -->
    <el-dialog
      title="出栏明细筛选"
      :visible.sync="dialogTableVisible"
      width="80%"
    >
      <!-- 条件筛选 -->
      <div>
        <el-form
          class="fowlOut-form"
          :inline="true"
          :model="fowlOutsForm"
        >
          <el-form-item prop="afcNum" label="防伪码：">
            <el-input
              v-model="fowlOutsForm.afcNum"
              size="medium"
              placeholder="全部"
              clearable
            ></el-input>
          </el-form-item>

          <el-form-item>
            <el-button class="ym-input-left" type="primary" round size="medium" @click="search"
              >查询</el-button
            >
            <el-button plain round size="medium" @click="reset">重置</el-button>
          </el-form-item>
        </el-form>
      </div>
      <div class="fowlOut-table">
        <!-- 操作按钮 -->
        <!-- <div class="btns">
          <el-button type="primary" round size="small" @click="toAdd">
            <i class="iconfont iconsaomachulan"></i>
            扫码出栏
          </el-button>
        </div> -->
        <!--表格-->
        <el-table
          :data="tableData"
          v-loading="loading"
          highlight-current-row
          class="ym-table"
          size="mini"
          stripe
          border
          height="355"
        >
          <el-table-column
            type="index"
            label="序号"
            :index="indexMethod"
            width="60"
            align="center"
          ></el-table-column>

          <el-table-column
            prop="afcNum"
            label="防伪码"
            min-width="200"
            align="center"
          ></el-table-column>

          <el-table-column
            prop="qrcodeUrl"
            label="二维码"
            min-width="200"
            align="center"
          >
            <template slot-scope="scope">
              <el-popover
              placement="top"
              width="260"
              trigger="hover">
              <img :src="scope.row.qrcodeUrl" alt="" width="230">
              <el-avatar v-if="scope.row.qrcodeUrl" slot="reference" shape="square" :src="scope.row.qrcodeUrl"></el-avatar>
          </el-popover>
            </template>
          </el-table-column>

          <el-table-column
            prop="productName"
            label="产品名称"
            min-width="200"
            align="center"
          ></el-table-column>

          <el-table-column
            prop="batchNum"
            label="产品批次编号"
            min-width="200"
            align="center"
          ></el-table-column>

          <el-table-column
            prop="vervelMac"
            label="鸡脚环MAC"
            min-width="200"
            align="center"
          ></el-table-column>

          <el-table-column
            prop="isOut"
            label="未出栏/出栏"
            align="center"
            min-width="120"
          >
            <template slot-scope="scope">
              <el-switch
                v-if="scope.row.enableFlag!==1"
                v-model="scope.row.isOut"
                @change="onSwitchChange(scope.$index, scope.row.isOut)"
              >
              </el-switch>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="comfirm-btns">
        <!-- 页码 -->
      <el-pagination
        v-if="page.total>=10"
        @size-change="pageSizeChange"
        @current-change="pageChange"
        :current-page="page.currentPage"
        :page-sizes="[10, 20, 30, 40]"
        :page-size="page.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="page.total"
      >
      </el-pagination>
        <el-button type="primary" size="medium" round @click="confirm"
          >确认提交</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      title: '添加出栏信息',
      loading: false,
      productId: '',
      afcBatchId: '',
      companyId: '',
      productParams: { enableFlag: 1 },
      batchParams: { inOutFlags: [2, 3], enableFlag: 1, tacheTypeId: 4 },
      companyParams: {
        enableFlag: 1,
        companyTypeIds: [100, 102], // 销售商
        flowFlag: 4 // 多返回养殖场安全处理自带商户一项
      },
      dialogTableVisible: false,
      outDetails: [], // 出栏明细返显
      isChange: false, // 修改按钮
      isOpen: false, // 默认可选择产品批次编号
      page: {
        pageSize: 10,
        currentPage: 1,
        total: 40
      },
      // 表单对象
      form: {
        productId: '', // 产品名称ID
        productName: '', // 产品名称
        afcBatchId: '', // 产品批次编号ID
        batchNum: '', // 产品批次编号
        outCompanyId: '', // 出栏下游商户
        outCompanyName: '', // 出栏下游商户名称
        afcBatchVervels: [], // 出栏明细信息
        outQty: 0, // 出栏数量
        remark: '' // 备注
      },
      rules: {
        productId: [
          { required: true, message: '请选择产品名称', trigger: 'change' }
        ],
        afcBatchId: [
          { required: true, message: '请选择产品批次编号', trigger: 'change' }
        ],
        outCompanyId: [
          { required: true, message: '请选择商户名称', trigger: 'change' }
        ],
        afcBatchVervels: [
          {
            required: true,
            message: '请筛选出栏明细',
            trigger: 'blur'
          }
        ],
        outQty: [
          {
            required: true,
            message: '出栏数量',
            trigger: 'blur'
          }
        ],
        remark: [
          {
            required: true,
            message: '请填写出栏备注',
            trigger: 'blur'
          }
        ]
      },
      tableData: [],
      fowlOutsForm: {
        afcNum: ''
      }
    }
  },
  computed: {
    id() {
      return this.$route.query.id
    }
  },
  watch: {},
  created() {
    if (this.id) {
      this.title = '修改出栏信息'
      this.batchParams.inOutFlag = 3
      this.isOpen = true // 关闭产品批次选择
      this.get()
    }
  },
  methods: {
    goBack() {
      this.$router.push('/listFowlOuts')
    },
    // 筛选数据
    onSwitchChange(index, isOut) {
      console.log('switch输出：', isOut, index)
      if (isOut) {
        this.tableData[index].isOut = true
      } else {
        this.tableData[index].isOut = false
      }
      // console.log('出栏数据:', this.tableData)
    },

    // 提交出栏明细
    confirm() {
      // 清空原始数据
      this.form.afcBatchVervels = []
      this.outDetails = []
      this.form.outQty = 0
      // 提交选中数据
      const tableData = this.tableData
      for (var item of tableData) {
        if (item.isOut) {
          const obj = {}
          obj.afcBatchVervelId = item.afcBatchVervelId
          obj.afcNum = item.afcNum
          obj.qrcodePath = item.qrcodePath
          obj.vervelMac = item.vervelMac
          this.form.afcBatchVervels.push(obj) // 提交的数据
          this.outDetails.push(item.afcNum) // 返显出栏明细
          this.form.outQty += 1
        }
      }
      // 判断数据是否为空
      if (this.form.outQty === 0) {
        this.$message.error('出栏数据为空')
        this.isChange = false
      } else {
        this.dialogTableVisible = false
        this.isChange = true
      }

      console.log('提交结果:', this.form.afcBatchVervels)
      // console.log('出栏数量:', this.form.outQty)
    },
    indexMethod(index) {
      if (index + 1 < 10) {
        return '0' + (index + 1)
      }
      return index + 1
    },
    // 选择产品名称
    onProductChange(val) {
      if (!val) {
        this.form.productId = ''
        this.form.productName = ''
      } else {
        this.form.productId = val.productId
        this.form.productName = val.productName
      }
    },

    // 选择产品批次编号
    onBatchChange(val) {
      if (!val) {
        this.form.afcBatchId = ''
        this.form.batchNum = ''
        this.tableData = []
        this.form.afcBatchVervels = []
        this.outDetails = []
      } else {
        this.form.afcBatchId = val.afcBatchId
        this.form.batchNum = val.batchNum
        if (this.id && this.form.afcBatchId) {
          // 显示当前出栏和未出栏的数据
          const params = {
            afcBatchId: val.afcBatchId,
            afcBatchOutId: this.id
          }
          this.getVervel(params)
        } else if (!this.id && this.form.afcBatchId) {
          // 新增出栏的明细（显示未出栏的数据）
          const params = {
            afcBatchId: val.afcBatchId,
            enableFlag: 2
          }
          this.getVervel(params)
        }
      }
    },
    // 选择出栏下游商户
    onCompanyChange(val) {
      if (!val) {
        this.form.outCompanyId = ''
        this.form.outCompanyName = ''
      } else {
        this.form.outCompanyId = val.companyId
        this.form.outCompanyName = val.companyName
      }
    },
    // 条件搜索
    search() {
      if (this.fowlOutsForm.afcNum) {
        if (this.id) {
          const params = {
            afcNum: this.fowlOutsForm.afcNum,
            afcBatchId: this.form.afcBatchId,
            afcBatchOutId: this.id
          }
          this.getVervel(params)
        } else {
          const params = {
            afcNum: this.fowlOutsForm.afcNum,
            afcBatchId: this.form.afcBatchId,
            enableFlag: 2
          }
          this.getVervel(params)
        }
      } else {
        const params = {
          afcBatchId: this.form.afcBatchId,
          enableFlag: 2
        }
        this.getVervel(params)
      }
    },
    // 条件重置
    reset() {
      this.fowlOutsForm.afcNum = ''
      if (this.id) {
        const params = {
          afcBatchId: this.form.afcBatchId,
          afcBatchOutId: this.id
        }
        this.getVervel(params)
      } else {
        const params = {
          afcBatchId: this.form.afcBatchId,
          enableFlag: 2
        }
        this.getVervel(params)
      }
    },
    pageSizeChange(val) {
      this.page.pageSize = val
      if (this.id) {
        const params = {
          afcBatchId: this.form.afcBatchId,
          afcBatchOutId: this.id,
          current: this.page.currentPage,
          size: val
        }
        this.getVervel(params)
      } else {
        const params = {
          afcBatchId: this.form.afcBatchId,
          enableFlag: 2,
          current: this.page.currentPage,
          size: val
        }
        this.getVervel(params)
      }
    },
    pageChange(val) {
      this.page.currentPage = val
      if (this.id) {
        const params = {
          afcBatchId: this.form.afcBatchId,
          afcBatchOutId: this.id,
          current: val,
          size: this.page.pageSize
        }
        this.getVervel(params)
      } else {
        const params = {
          afcBatchId: this.form.afcBatchId,
          enableFlag: 2,
          current: val,
          size: this.page.pageSize
        }
        this.getVervel(params)
      }
    },
    // 提交表单
    submit() {
      this.$refs['form'].validate(async(valid) => {
        if (!valid) return
        else {
          if (this.id) {
            this.edit()
          } else {
            this.add()
          }
        }
      })
    },
    // 查询
    async get() {
      const params = {
        afcBatchOutId: this.id
      }
      const { data } = await this.$http.fowlOutsInfor.getFowlOut(params)
      if (data.code !== '0') return this.$message.error(data.msg || '查询失败')
      // 回显表单
      console.log('查询数据：', data.data)
      this.form = data.data
      this.companyId = data.data.outCompanyId
      this.afcBatchId = data.data.afcBatchId
      this.productId = data.data.productId
      // 修改出栏明细时调用出栏明细列表
      const _params = {
        afcBatchId: this.form.afcBatchId,
        afcBatchOutId: this.id
      }
      this.getVervel(_params)
    },
    // 查询出栏明细列表
    async getVervel(params) {
      const { data } = await this.$http.fowlOutsInfor.listVervels(params)
      if (data.code !== '0') return this.$message.error(data.msg || '查询失败')
      // 回显表单
      console.log('查询数据：', data.data.records)
      this.page.total = parseInt(data.data.total)
      this.page.currentPage = parseInt(data.data.current)
      const records = data.data.records
      var array = []
      for (var item of records) {
        var obj = {}
        obj.afcNum = item.afcNum
        obj.qrcodePath = item.qrcodePath
        obj.qrcodeUrl = item.qrcodeUrl
        obj.productName = item.productName
        obj.productId = item.productId
        obj.afcBatchId = item.afcBatchId
        obj.batchNum = item.batchNum
        obj.afcBatchVervelId = item.afcBatchVervelId
        obj.vervelMac = item.vervelMac
        obj.enableFlag = item.enableFlag
        if (item.enableFlag === 3) {
          obj.isOut = true // 修改出栏信息时默认选中状态
          this.outDetails.push(item.afcNum) // 返显出栏明细
          this.isChange = true // 显示修改按钮
        } else if (item.enableFlag === 3) {
          obj.isOut = false // 修改出栏信息时默认未选中
        }
        array.push(obj)
      }
      this.tableData = array
    },
    // 添加
    async add() {
      const params = { ...this.form }
      const { data } = await this.$http.fowlOutsInfor.saveFowlOut(params)
      if (data.code !== '0') return this.$message.error(data.msg || '新增失败')
      this.$message.success('新增成功')
      this.goBack()
    },
    // 修改
    async edit() {
      const params = { ...this.form }
      const { data } = await this.$http.fowlOutsInfor.updateFowlOut(params)
      if (data.code !== '0') return this.$message.error(data.msg || '修改失败')
      this.$message.success('修改成功')
      this.goBack()
    }

  }
}
</script>

<style lang="scss">
.save-form-page {
  .el-form1 {
    margin-top: 40px;
  }

  .fowlOut-form {
    margin-top: 20px;
    padding: 0 24px;
  }

  .fowlOut-table {
    margin: 0 24px 24px 24px;
    padding: 20px;
    background-color: rgba(250, 250, 250, 1);
  }
  .btns {
    text-align: right;
    margin-bottom: 10px;
  }
  .comfirm-btns {
    text-align: center;
    padding-bottom: 20px;
  }

  .warning {
    color: #e6a23c !important;
  }

  .el-input {
    width: 400px;
  }

  .el-textarea {
    width: 400px;
  }

  .btn-left {
    margin-left: 32px;
  }

  //按钮图标样式调整
  .iconfont {
    font-family: 'iconfont' !important;
    font-size: 14px;
    font-style: normal;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  .el-pagination{
    margin-right: 24px;
    margin-bottom: 24px;
  }

  .el-pagination__editor.el-input {
    width: 50px;
}
}
</style>
